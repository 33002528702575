import React, { useEffect, useState } from 'react';
import {
    useDisclosure,
} from "@chakra-ui/react";
import Modal from '../elements/modal';
import ModalTransfer from '../elements/modalTransfer'
import AlertDialogCustom from '../elements/alertDialog';
import axios from "axios";
import Config from '../../utils/Config.js';
import { useToast, Box, Spinner } from '@chakra-ui/react';
import { FaEraser } from 'react-icons/fa';
import contracts from '../../contracts';
import Web3 from 'web3';
import { ethers } from 'ethers';


export default function Member() {

    const { isOpen: isOpenModalAdd, onOpen: onOpenModalAdd, onClose: onCloseModalAdd } = useDisclosure()
    const { isOpen: isOpenModalTransfer, onOpen: onOpenModalTransfer, onClose: onCloseModalTransfer } = useDisclosure()
    const [isOpenDialog, setIsOpenDialog] = useState(false)
    const [deleteElement, setDeleteElement] = useState({ name: '', id: '' })
    const [wallet, setWallet] = useState([])
    const [walletAmount, setwalletAmount] = useState(0)
    const [errorMessage, setErrorMessage] = useState(null)
    const [message, setMessage] = useState(null);
    const [DataisLoaded, setDataisLoaded] = useState(false)
    const toast = useToast()
    const [balanceContract, setBalanceContract] = useState(0)

    const [Excel, setExcel] = useState('');
    var fileInput = React.createRef();

    useEffect(() => {
        const ConfigHeader = {
            headers: {
                'Authorization': "JWT " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
                'accept': 'application/json'
            }
        }

        axios.get(Config.apiURL + "/get_members/", ConfigHeader).then(res => {
            setWallet(res.data)
            setwalletAmount(res.data.length)
            setDataisLoaded(true)

        });

        getContractBalance()

    }, []);

    useEffect(() => {
        if (errorMessage) {
            toast({
                position: 'bottom-right',
                render: () => (
                    <Box color='white' p={3} bg='red.500'>
                        {errorMessage}
                    </Box>
                ),
            })
            setTimeout(function () { setErrorMessage(null); }, 500)
        }
        if (message) {
            toast({
                position: 'bottom-right',
                render: () => (
                    <Box color='white' p={3} bg='green.500'>
                        {message}
                    </Box>
                ),
            })
            setTimeout(function () { setMessage(null); }, 500)
        }

    }, [errorMessage, message])

    const deleteElementF = (pk) => {
        const ConfigHeader = {
            headers: {
                'Authorization': "JWT " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
                'accept': 'application/json'
            }
        }
        axios.post(Config.apiURL + "/wallet_delete/" + pk, { free_mint: false, whitelist: false, member: true, prewhitelist: false }, ConfigHeader).then(() => {
            setMessage('Delete successfully')
            window.location.reload();
        });

    }

    // const handleExcel = (event) => {
    //     const target = event.target
    //     const name = target.name
    //     let hojas = []
    //     if (name === 'file') {
    //         let reader = new FileReader()
    //         reader.readAsArrayBuffer(fileInput.current.files[0])
    //         reader.onloadend = (e) => {
    //             var data = new Uint8Array(e.target.result);
    //             var workbook = XLSX.read(data, { type: 'array' });

    //             workbook.SheetNames.forEach(function (sheetName) {
    //                 var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
    //                 hojas.push({
    //                     data: XL_row_object,
    //                     sheetName
    //                 })
    //             })
    //             setExcel(hojas)

    //         }
    //     }

    // }

    // const populateWhitelist = () => {
    //     if(Excel===''){
    //         setErrorMessage('You have to upload an excel file first');
    //     }else{
    //         const ConfigHeader = {
    //             headers: {
    //                    'Authorization': "JWT " + localStorage.getItem('access_token'),
    //                    'Content-Type': 'application/json',
    //                    'accept': 'application/json'
    //                }
    //              }

    //         var data = { walletlist: Excel }

    //     axios.post(Config.apiURL + "/add_list_freemint/", data, ConfigHeader).then(() => {
    //         window.location.reload();
    //     }).catch(error => {
    //         setErrorMessage(error.message);
    //     }); 
    //     }
    // }

    const addMemberUsers = async () => {
        if (window.ethereum) {
            if (window.ethereum.isMetaMask) {
                // aqui va el array con los users
                try {
                    const users = wallet
                        .filter(address => Web3.utils.isAddress(address.wallet.replace(/\s+/g, '')))
                        .map(x => x.wallet)


                    const web3 = new Web3(window.ethereum)
                    const { Factory } = contracts

                    const contract = await new web3.eth.Contract(Factory.ABI, Factory.Address)
                    const addMember = await contract.methods.setMembers(users).send({ from: window.ethereum._state.accounts[0] })

                    setMessage('Member list added successfully to contract')
                    console.log(addMember)
                }
                catch (e) {
                    setErrorMessage(e.message)
                }
            }
        }
    }


    const transferBalanceToMembers = async () => {
        if (window.ethereum) {
            if (window.ethereum.isMetaMask) {
                try {
                    const web3 = new Web3(window.ethereum)
                    const { Factory } = contracts

                    const contract = await new web3.eth.Contract(Factory.ABI, Factory.Address)
                    const transfer = await contract.methods.withdrawAll().send({ from: window.ethereum._state.accounts[0] })

                    getContractBalance()

                    setMessage('Balance transferred successfully')
                    console.log(transfer)
                }
                catch (e) {
                    setErrorMessage(e.message)
                }
            }

        }
    }

    const getContractBalance = async () => {
        const { Factory } = contracts

        window.ethereum.request({ method: 'eth_getBalance', params: [Factory.Address, 'latest'] })
            .then(balance => {
                console.log(ethers.utils.formatEther(balance))
                setBalanceContract(ethers.utils.formatEther(balance));
            })
            .catch(error => {
                console.log(error.message)
                setErrorMessage(error.message);
            });
    };

    return (
        <React.Fragment>
            <div className="container-fluid">

                <div className="row">
                    <div className="col-6">
                        <h3 className="mb-2 text-gray-800">Wallets Members</h3>
                        <span>Balance: {balanceContract}</span>
                    </div>
                    {/* <div className="col-6" style={{ float: "right" }} style={{marginBottom:'20px'}}>
                        <InputGroup size='md'>
                        <InputLeftAddon children='Excel' />
                            <Input type="file" name="file" id="file" onChange={handleExcel} ref={fileInput} placeholder='Enter password'
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                            <InputRightElement width='4.5rem'>
                                <a href="#" className="btn btn-info btn-icon-split" style={{ float: "right" }} onClick={populateWhitelist}>
                                    <span className="text">Upload</span>
                                </a>
                            </InputRightElement>
                        </InputGroup>


                    </div> */}

                    <div className='col-6'>
                        <a href="#" className="btn btn-success btn-icon-split disabled" style={{ float: "right" }} onClick={onOpenModalTransfer} >
                            <span className="text">Transfer balance</span>
                        </a>


                        <a href="#" className="btn btn-success btn-icon-split mr-2 mb-2 disabled" style={{ float: "right" }} onClick={transferBalanceToMembers} >
                            <span className="text">Transfer balance to all members</span>
                        </a>
                    </div>




                </div>

                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <a href="#" className="btn btn-success btn-icon-split disabled  " style={{ float: "right" }} onClick={addMemberUsers} >
                            <span className="text">Add member list to contract</span>
                        </a>

                        <a href="#" className="btn btn-success btn-icon-split mr-2" style={{ float: "right" }} onClick={onOpenModalAdd} >
                            <span className="text">Add Wallet</span>
                        </a>
                    </div>

                    <div className="card-body">
                        <div className="table-responsive">
                            {DataisLoaded ?
                                <table className="table table-bordered" id="dataTable" width="100%" >
                                    <thead>
                                        <tr>

                                            <th>Wallet</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            wallet.length > 0 ?
                                                wallet.map((item, index) => (
                                                    <tr key={index}>

                                                        <td>{item.wallet}</td>
                                                        <td><a href="#" className="btn btn-danger btn-circle btn-sm" title="Delete" onClick={() => { setIsOpenDialog(true); setDeleteElement({ name: item.email, id: item.id }) }}>
                                                            <FaEraser />
                                                        </a></td>
                                                    </tr>
                                                ))
                                                :
                                                <tr>
                                                    <td colSpan={4}>No hay elementos que mostrar</td>
                                                </tr>
                                        }

                                    </tbody>
                                </table>
                                : <Spinner
                                    thickness='4px'
                                    speed='0.65s'
                                    emptyColor='gray.200'
                                    color='blue.500'
                                    size='xl'

                                />
                            }
                        </div>
                    </div>

                </div>

            </div>
            <Modal onClose={onCloseModalAdd} isOpen={isOpenModalAdd} member={true} freeMint={false} setErrorMessage={setErrorMessage} setMessage={setMessage}/>
            <ModalTransfer onClose={onCloseModalTransfer} isOpen={isOpenModalTransfer} getContractBalance={getContractBalance} setMessage={setMessage}/>
            <AlertDialogCustom isOpen={isOpenDialog} setIsOpen={setIsOpenDialog} accept={deleteElementF} element={deleteElement} />

        </React.Fragment>
    );
}
