import React, { useState, useEffect } from 'react';
import NavLink from "../elements/nav_link";
import {useToast, Box, Button } from '@chakra-ui/react';
import contracts from '../../contracts'
import Web3 from 'web3'


export default function Sidebar() {

    const [errorMessage, setErrorMessage] = useState(null)
    const [isPaused, setIsPaused] = useState(false)
    const [isPublic, setIsPublic] = useState(false)
    const [message, setMessage] = useState(null); 
    const toast = useToast();

    useEffect(async () => {
        if (window.ethereum) {
            if (window.ethereum.isMetaMask) {
                try {
                    const web3 = new Web3(window.ethereum)
                    const { BG } = contracts

                    const contract = new web3.eth.Contract(BG.ABI, BG.Address)

                    const pub = await contract.methods.publicSalesState().call()
                    const paus = await contract.methods.pauseState().call()

                    setIsPublic(pub)
                    setIsPaused(paus)
                } catch (e) {
                    setErrorMessage(e.message)
                }
            }
        }
    }, [])

    useEffect(() => {
        if (errorMessage) {
            toast({
                position: 'bottom-right',
                render: () => (
                    <Box color='white' p={3} bg='red.500'>
                        {errorMessage}
                    </Box>
                ),
            })
            setTimeout(function () { setErrorMessage(null); }, 500)
        }
        if (message) {
            toast({
                position: 'bottom-right',
                render: () => (
                    <Box color='white' p={3} bg='green.500'>
                        {message}
                    </Box>
                ),
            })
            setTimeout(function () { setMessage(null); }, 500)
        }

    }, [errorMessage, message])


    const deactivatePresale = async () => {
        if (window.ethereum) {
            if (window.ethereum.isMetaMask) {
                try {
                    const web3 = new Web3(window.ethereum)
                    const { BG } = contracts

                    const contract = await new web3.eth.Contract(BG.ABI, BG.Address)
                    const presale = await contract.methods.setPublicSalesState(true).send({ from: window.ethereum._state.accounts[0] })
                    setMessage('Presale deactivated')
                    console.log(presale)
                }
                catch (e) {
                    setErrorMessage(e.message)
                }
            }
        }
    }

    const activatePresale = async () => {
        if (window.ethereum) {
            if (window.ethereum.isMetaMask) {
                try {
                    const web3 = new Web3(window.ethereum)
                    const { BG } = contracts

                    const contract = await new web3.eth.Contract(BG.ABI, BG.Address)
                    const presale = await contract.methods.setPublicSalesState(false).send({ from: window.ethereum._state.accounts[0] })
                    setMessage('Presale activated')
                    console.log(presale)
                }
                catch (e) {
                    setErrorMessage(e.message)
                }
            }
        }
    }

    const initContract = async () => {
        if (window.ethereum) {
            if (window.ethereum.isMetaMask) {
                const web3 = new Web3(window.ethereum)
                const { BG } = contracts

                const contract = await new web3.eth.Contract(BG.ABI, BG.Address)
                const init = await contract.methods.setPauseState(false).send({from: window.ethereum._state.accounts[0]})
                setMessage('Contract unpaused')

                console.log(init)
            }
        }
    }

    const pauseContract = async () => {
        if (window.ethereum) {
            if (window.ethereum.isMetaMask) {
                const web3 = new Web3(window.ethereum)
                const { BG } = contracts

                const contract = await new web3.eth.Contract(BG.ABI, BG.Address)
                const pause = await contract.methods.setPauseState(true).send({from: window.ethereum._state.accounts[0]})
                setMessage('Contract paused')

                console.log(pause)
            }
        }
    }

    return (
        <React.Fragment>

            <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">


                <span className="sidebar-brand d-flex align-items-center justify-content-center" style={{ backgroundColor: '#F3F3F3' }}>
                    <div className="sidebar-brand-icon" >
                        <img alt="Battle Growlies" width="70%" data-sticky-width="82" data-sticky-height="40" src="assets/img/logo.jpg" style={{ marginLeft: '20px' }} />
                    </div>

                </span>


                <hr className="sidebar-divider my-0"></hr>

                <div className="nav-item active" style={{ marginTop: "20px" }}>
                    <NavLink to="/user" className="nav-link">
                        <i className="fas fa-fw fa-table"></i>
                        <span >User</span>
                    </NavLink>

                </div>


                <div className="nav-item active" >
                    <NavLink to="/dashboard" className="nav-link">
                        <i className="fas fa-fw fa-table"></i>
                        <span >Wallet</span>
                    </NavLink>

                </div>

                <div className="nav-item active">
                    <NavLink to="/whitelist" className="nav-link">
                        <i className="fas fa-fw fa-table"></i>
                        <span >Whitelist</span>
                    </NavLink>

                </div>


                {/* <div className="nav-item active">
                <NavLink to="/free_mint_team" className="nav-link">
                    <i className="fas fa-fw fa-table"></i>
                    <span >Free Mint Team</span>
                </NavLink>

                </div> */}

                <div className="nav-item active">
                    <NavLink to="/free_mint" className="nav-link">
                        <i className="fas fa-fw fa-table"></i>
                        <span >Free Mint</span>
                    </NavLink>

                </div>


                <div className="nav-item active">
                    <NavLink to="/members" className="nav-link">
                        <i className="fas fa-fw fa-table"></i>
                        <span >Members</span>
                    </NavLink>

                </div>

                <hr className="sidebar-divider d-none d-md-block"></hr>


                {/* <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='presales' mb='0' style={{color: 'white', marginLeft: '20px'}}>
                        Presale
                    </FormLabel>
                    <Switch id='presales' mb='0'  />
                </FormControl> */}

                <Button disabled={isPublic} colorScheme='grey' width='75%' style={{ transform: 'translate(20%, 0%)' }} onClick={activatePresale}>Activate Presale</Button>
                <Button disabled={!isPublic} colorScheme='grey' width='75%' style={{ transform: 'translate(20%, 0%)' }} onClick={deactivatePresale}>Deactivate Presale</Button>
                <Button disabled={isPaused} colorScheme='grey' width='75%' style={{transform: 'translate(20%, 0%'}} onClick={initContract}>Unpause Contract</Button>
                <Button disabled={!isPaused} colorScheme='grey' width='75%' style={{transform: 'translate(20%, 0%'}} onClick={pauseContract}>Pause Contract</Button>
            </ul>

        </React.Fragment>
    );
}
