import React, { useEffect, useState } from 'react'
import contracts from '../../contracts'
import Web3 from 'web3'
import axios from 'axios'
import Config from '../../utils/Config'

const Mintt = ({ whitelist, totalMinted, setTotalMinted, freeMint, freeMinted, setFreeMinted, isPresale, setWaiting }) => {

    const [cantNFT, setCantNFT] = useState(1)
    const total = isPresale ? 3 : 10
    const [disabledMint, setDisabledMint] = useState(parseInt(totalMinted) >= total ? true : false)
    const [disabledFreeMint, setDisabledFreeMint] = useState(freeMinted)

    useEffect(() => {
        setDisabledMint(parseInt(totalMinted) >= total)
    }, [totalMinted]);

    useEffect(() => {
        setDisabledFreeMint(freeMinted)
    }, [freeMinted]);

    const sumNFT = () => {
        let intNFTS = parseInt(totalMinted)
        if ((cantNFT + intNFTS) < total)
            setCantNFT(cantNFT + 1)
    }

    const minNFT = () => {
        if (cantNFT > 1)
            setCantNFT(cantNFT - 1)
    }

    const mintLootBox = async () => {
        if (window.ethereum) {
            if (window.ethereum.isMetaMask) {
                const web3 = new Web3(window.ethereum)
                const { BG } = contracts
                try {
                    setWaiting(true)

                    const contract = await new web3.eth.Contract(BG.ABI, BG.Address)
                    const mintFee = await contract.methods.getPrice().call() * cantNFT
                    setDisabledMint(true)

                    console.log('cant', cantNFT)

                    const wallet = window.ethereum._state.accounts[0]

                    if (Web3.utils.isAddress(wallet)) {
                        axios.get(Config.apiURL+'/signature_whitelist/'+wallet)
                        .then( async res => {
                            console.log(res.data)
                            await contract.methods.SignedMint(
                                res.data.nonce,
                                cantNFT,
                                3,
                                false, 
                                res.data.validation
                            ).send({ from: wallet, value: mintFee })
                        }).catch(err => {
                            console.log(err)
                        })
                    }

                    setCantNFT(1)

                    let totalM = totalMinted

                    if(isPresale)    
                        totalM = await contract.methods.getWlQtyMintedByGrowlie(window.ethereum._state.accounts[0]).call()
                    else
                        totalM = await contract.methods.getPubQtyMintedByGrowlie(window.ethereum._state.accounts[0]).call()
                    setWaiting(false)
                    setTotalMinted(totalM)
                    setDisabledMint(parseInt(totalM) >= total)
                }
                catch (e) {
                    setWaiting(false)
                    setDisabledMint(parseInt(totalMinted) >= total)
                    console.log(e)
                }

            }
        }
    }

    const mintFreeLootBox = async () => {
        if (window.ethereum) {
            if (window.ethereum.isMetaMask) {
                const web3 = new Web3(window.ethereum)
                const { BG } = contracts

                try {
                    setWaiting(true)

                    const contract = await new web3.eth.Contract(BG.ABI, BG.Address)

                    const wallet = window.ethereum._state.accounts[0]

                    if (Web3.utils.isAddress(wallet)) {
                        axios.get(Config.apiURL+'/signature_freemint/'+wallet)
                        .then( async res => {
                            console.log(res.data)
                            await contract.methods.SignedMint(
                                res.data.nonce,
                                1,
                                1,
                                true,
                                res.data.validation
                            ).send({ from: wallet })
                        }).catch(err => {
                            console.log(err)
                        })
                    }

                    const free = await contract.methods.freeClaimQtyMintedByGrowlie(window.ethereum._state.accounts[0]).call()
                    setWaiting(false)
                    setFreeMinted(free)
                    setDisabledFreeMint(free)

                }
                catch (e) {
                    setWaiting(false)
                    setDisabledFreeMint(freeMinted)
                }


            }
        }
    }

    return (

        <div className="row banner" style={{ margin: 0, zIndex: 100 }}>
            <img src="assets/template/img/presale.png" className="img-fluid m-auto" alt="banner" />

            <div className="waiting">

                {(whitelist || !isPresale) &&
                    <div>
                        <div className="qty">
                            <p className='text-waiting mint-text' style={{ fontSize: '25px', textTransform: 'none' }}>Welcome to the presale!</p>
                            <p className='text-waiting mb-3 mint-text' style={{ fontSize: '15px', textTransform: 'none', fontFamily: 'Averta' }} >
                                You are one of the 3,000 wlisters that will be able to mint up to 3 growlies. Good luck!
                            </p>
                            <span className="plus bg-dark boxShadow" onClick={sumNFT}>+</span>
                            <input type="number" className="count" name="qty" value={cantNFT} min={1} max={total} readOnly />
                            <span className="minus bg-dark boxShadow" onClick={minNFT}>-</span>
                        </div>

                        <button className="mint boxShadow" disabled={disabledMint} onClick={mintLootBox}>MINT</button>
                    </div>
                }
                {freeMint &&
                    <div>
                        <hr  color='blue' className="mintline" />
                        <button className="mint boxShadow" disabled={disabledFreeMint} onClick={mintFreeLootBox}>1 FREE MINT</button>
                    </div>

                }

            </div>
        </div>
    )
}

export default Mintt
