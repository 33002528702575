import React, { useEffect, useState } from 'react';
import {   
    useDisclosure,
} from "@chakra-ui/react";
import Modal from '../elements/modalUser';
import AlertDialogCustom from '../elements/alertDialog';
import axios from "axios";
import Config from '../../utils/Config.js';
import {useToast, Box, Spinner } from '@chakra-ui/react'
import { FaEraser } from 'react-icons/fa';
import { useNavigate  } from "react-router-dom";

export default function User() {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isOpenDialog, setIsOpenDialog] = useState(false)
    const [deleteElement, setDeleteElement] = useState({ name: '', id: '' })
    const [wallet, setWallet] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [message, setMessage] = useState(null);
    const [DataisLoaded, setDataisLoaded] = useState(false)
    const toast = useToast()
    const navigate  = useNavigate ()

   
    useEffect(() => {

        const ConfigHeader = {
            headers: {
                   'Authorization': "JWT " + localStorage.getItem('access_token'),
                   'Content-Type': 'application/json',
                   'accept': 'application/json'
               }
             }

        if(localStorage.getItem('access_token'))
        {
            axios.get(Config.apiURL + "/user", ConfigHeader).then(res => {
                setWallet(res.data)
                setDataisLoaded(true)
    
            });
        }else{
        navigate("/login");
        }     
    }, []);

    useEffect(() => {
        if (errorMessage) {
            toast({
                position: 'bottom-right',
                render: () => (
                    <Box color='white' p={3} bg='red.500'>
                        {errorMessage}
                    </Box>
                ),
            })
            setTimeout(function () { setErrorMessage(null); }, 500)
        }
        if (message) {
            toast({
                position: 'bottom-right',
                render: () => (
                    <Box color='white' p={3} bg='green.500'>
                        {message}
                    </Box>
                ),
            })
            setTimeout(function () { setMessage(null); }, 500)
        }

    }, [errorMessage, message])

    const deleteElementF = (pk) => {
        const ConfigHeader = {
            headers: {
                   'Authorization': "JWT " + localStorage.getItem('access_token'),
                   'Content-Type': 'application/json',
                   'accept': 'application/json'
               }
             }
        axios.post(Config.apiURL + "/user_delete/" + pk,'', ConfigHeader).then(() => {
            window.location.reload();
            setMessage('Delete successfully')

        });

    }

    return (
        <React.Fragment>
            <div className="container-fluid">

                <div className="row">
                    <div className="col-6">
                        <h3 className=" mb-2 text-gray-800">Users</h3>
                    </div>
                   

                </div>

                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <a href="#" className="btn btn-success btn-icon-split" style={{ float: "right" }} onClick={onOpen} >
                            <span className="text">Add User</span>
                        </a>
                    </div>

                    <div className="card-body">
                        <div className="table-responsive">
                            {DataisLoaded ?
                                <table className="table table-bordered" id="dataTable" width="100%" >
                                    <thead>
                                        <tr>
                                           
                                            <th>Username</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            wallet.length > 0 ?
                                                wallet.map((item, index) => (
                                                    <tr key={index}>
                                                       
                                                        <td>{item.username}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.email}</td>
                                                        <td><a href="#" className="btn btn-danger btn-circle btn-sm" title="Delete" onClick={() => { setIsOpenDialog(true); setDeleteElement({ name: item.username, id: item.id }) }}>
                                                        <FaEraser />
                                                        </a></td>
                                                    </tr>
                                                ))
                                                :
                                                <tr>
                                                    <td colSpan={4}>No hay elementos que mostrar</td>
                                                </tr>
                                        }

                                    </tbody>
                                </table>
                                : <Spinner
                                    thickness='4px'
                                    speed='0.65s'
                                    emptyColor='gray.200'
                                    color='blue.500'
                                    size='xl'

                                />
                            }
                        </div>
                    </div>

                </div>

            </div>
            <Modal onClose={onClose} isOpen={isOpen} setErrorMessage={setErrorMessage} setMessage={setMessage}/>
            <AlertDialogCustom isOpen={isOpenDialog} setIsOpen={setIsOpenDialog} accept={deleteElementF} element={deleteElement} />

        </React.Fragment>
    );
}
