import React, {useEffect, useState} from 'react';
import Mintt from './mint';
import Rellax from "rellax";
import PreWhitelistPage from './preWhitelistPage';
import RegisterManual from './registroManual';
import {useToast, Box, Spinner } from '@chakra-ui/react';
import Waiting from './waiting'
import WhitelistPage from './whitelistPage'
import Minted from './minted'

export default function Main(
    { 
        active,
        totalMinted,
        setTotalMinted,
        freeMinted,
        setFreeMinted,
        isPresale,
        preWhitelist,
        setPreWhitelist,
        account,
        whitelistBD,
        freeMintBD,
    }) {
    const [waiting, setWaiting] = useState(false)
    const [message, setMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const toast = useToast()

    useEffect(() => {
        new Rellax(".rellax", { 
          speed: -10,
          center: false,
          wrapper: null,
          round: true,
          vertical: true,
          horizontal: false
        });
    })


    useEffect(() => {
        if (errorMessage) {
            toast({
                position: 'bottom-right',
                render: () => (
                    <Box color='white' p={3} bg='red.500'>
                        {errorMessage}
                    </Box>
                ),
            })
            setTimeout(function () { setErrorMessage(null); }, 500)
        }
        
    },[errorMessage])

    useEffect(()=>{
        
        if (message) {
            toast({
                position: 'bottom-right',
                render: () => (
                    <Box color='white' p={3} bg='green.500'>
                        {message}
                    </Box>
                ),
            })
            setTimeout(function () { setMessage(null); }, 500)
        }
       
    },[message])

    return (
        <React.Fragment>
            <main className="container-fluid" style={{ paddingRight: 0, paddingLeft: 0 }}>

            {   
                    waiting ? <Waiting /> 
                    : (!active && isPresale && (whitelistBD || freeMintBD)) ? <WhitelistPage /> 
                    : (!active && isPresale && preWhitelist && account) ? <PreWhitelistPage />
                    : (!active && isPresale && !preWhitelist && (!whitelistBD || !freeMintBD)) ?  <RegisterManual setPreWhitelist={setPreWhitelist} setErrorMessage={setErrorMessage} setMessage={setMessage} />
                    : ((active && isPresale && totalMinted == 3 && (freeMinted || !freeMintBD)) || (!isPresale && totalMinted == 10)) ? <Minted />
                    : (active && (whitelistBD || freeMintBD || !isPresale)) ?
                        <Mintt
                            totalMinted={totalMinted}
                            setTotalMinted={setTotalMinted}
                            freeMint={freeMintBD}
                            freeMinted={freeMinted}
                            setFreeMinted={setFreeMinted}
                            isPresale={isPresale}
                            setWaiting={setWaiting}
                            whitelist={whitelistBD}
                        />
                    : <div className="row banner" style={{ margin: 0, zIndex: 100 }}><img src="assets/template/img/banner.jpg" className="img-fluid m-auto" alt="banner" /> </div>   
                }


                <article className="content" style={{ zIndex: 0 }}>
                    <div className="one rellax item1" data-rellax-speed="4">
                        <img src="assets/template/img/icons/icon_botella-rota.png" />
                    </div>
                    <div className="one rellax item2" data-rellax-speed="1">
                        <img src="assets/template/img/icons/icon_shuriken.png" />
                    </div>
                    <div className="one rellax item3" data-rellax-speed="6">
                        <img src="assets/template/img/icons/icon_dispersor.png" />
                    </div>
                    <div className="one rellax item4" data-rellax-speed="9">
                        <img src="assets/template/img/icons/icon_shuriken_2.png" />
                    </div>
                    <div className="one rellax item5" data-rellax-speed="7">
                        <img src="assets/template/img/icons/icon_arma-negra.png" />
                    </div>
                    <div className="one rellax item6" data-rellax-speed="5">
                        <img src="assets/template/img/icons/icon_hamburguesa.png" />
                    </div>
                    <div className="one rellax item7" data-rellax-speed="2">
                        <img src="assets/template/img/icons/icon_arma-con-amarillo.png" />
                    </div>
                    <div className="one rellax item8" data-rellax-speed="5">
                        <img src="assets/template/img/icons/icon_violin.png" />
                    </div>
                    <div className="one rellax item9" data-rellax-speed="1">
                        <img src="assets/template/img/icons/icon_bate.png" />
                    </div>
                    <div className="one rellax item10" data-rellax-speed="3">
                        <img src="assets/template/img/icons/icon_espada.png" />
                    </div>
                    <div className="one rellax item11" data-rellax-speed="10">
                        <img src="assets/template/img/icons/icon_destornillador.png" />
                    </div>
                    <div className="one rellax item12" data-rellax-speed="3">
                        <img src="assets/template/img/icons/icon_sarten.png" />
                    </div>
                    <div className="one rellax item13" data-rellax-speed="2">
                        <img src="assets/template/img/icons/icon_shuriken_3.png" />
                    </div>
                    <div className="one rellax item14" data-rellax-speed="1">
                        <img src="assets/template/img/icons/icon_hacha.png" />
                    </div>
                    <div className="one rellax item15" data-rellax-speed="4">
                        <img src="assets/template/img/icons/icon_bola-estrella.png" />
                    </div>
                    <div className="one rellax item16" data-rellax-speed="6">
                        <img src="assets/template/img/icons/icon_arma.png" />
                    </div>
                    <div className="one rellax item17" data-rellax-speed="8">
                        <img src="assets/template/img/icons/icon_corazón.png" />
                    </div>
                    <div className="one rellax item18" data-rellax-speed="10">
                        <img src="assets/template/img/icons/icon_shuriken_3.png" />
                    </div>
                    <div className="one rellax item19" data-rellax-speed="5">
                        <img src="assets/template/img/icons/icon_pollo.png" />
                    </div>
                    <div className="container">
                        <div className="two row">
                            <div className="col-12 pb-4">
                                <h2 className="welcome text-center">Welcome to the Battle Growlies battleverse</h2>
                            </div>
                            <div className="col">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-10 col-md-10 col-lg-10 col-xl-10 m-auto ">
                                        <div className="welcome-icons-content">
                                            <p className="">Battle Growlies is a collection of 10,000 cute and feisty NFTs created by Onem, C_reu_D and DARO, a team of ultra-talented artists, marketers and engineers that have collaborated with some of the biggest brands in the world like: Nickelodeon, Adobe, Playdoh and Viacom… just to name a few!</p>
                                            <p className="">Battle Growlies is a one-of-a-kind collection in a universe where storytelling and 3D art merge into a colorful universe. Each Battle Growlie is exclusively minted, which means there is only one of each type with a specific combination... so consider your minted Growlie one of a kind!</p>
                                            <p>Apart from NFTs we are also venturing into the world of Comic books and short films, where each of your minted Growlies will have an important role into the development of our world and story. So keep in mind that you may mint up to 5 Growlies until all the available 10,000 units are sold out. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </article>
                <div id="roadmap" className="row pt-5 pl-5 pr-5 justify-content-center" style={{ margin: 0, display: 'flex', justifyContent: 'center', zIndex: 100 }}>
                    <div id="" className="col-12 justify-content-center mb-5 no-gutters">
                        <div className="row justify-content-center">
                            <div id="roadmapTitle" className="d-inline-block" style={{display: 'flex' }}><h2>Roadmap</h2></div>
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-8 col-xl-6" style={{ margin: 0 }}>
                        <div className="row mb-4 trans trans-white">
                            <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2 left-content"><span><img src="assets/template/img/star.png" width="60" className="img-fluid" alt="star" /></span></div>
                            <div className="col-xs-12 col-sm-8 col-md-9 col-lg-10 right-content">
                                <p><b>Growlie-List (wl):</b> There are five ways to get on the list. To find out how you can get on the growlie-list visit our Discord server and go to #Discord-Guide channel.</p>
                            </div>
                        </div>
                        <div className="row mb-4 trans trans-white">
                            <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2 left-content"><span><img src="assets/template/img/star.png" width="60" className="img-fluid" alt="star" /></span></div>
                            <div className="col-xs-12 col-sm-8 col-md-9 col-lg-10 right-content">
                                <p><b>Whitelist:</b> There are five ways to get whitelisted. To find how to you can get WL visit our Discord server and go to the #whitelist-info channel.</p>
                            </div>
                        </div>
                        <div className="row mb-4 trans">
                            <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2 left-content"><span style={{ fontSize: '30px' }}>10%</span></div>
                            <div className="col-xs-12 col-sm-8 col-md-9 col-lg-10 right-content">
                                <p>We will be giving away 10 Growlies and one Legendary Character!</p>
                            </div>
                        </div>
                        <div className="row mb-4 trans">
                            <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2 left-content"><span style={{ fontSize: '30px' }}>50%</span></div>
                            <div className="col-xs-12 col-sm-8 col-md-9 col-lg-10 right-content">
                                <p>We will be giving away 25 Growlies and one Legendary Character. We will also host a raffle amongst the holders to see which Growlies will be part of the comic book. This is where our comic book production begins!</p>
                            </div>
                        </div>
                        <div className="row mb-4 trans">
                            <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2 left-content"><span style={{ fontSize: '30px' }}>75%</span></div>
                            <div className="col-xs-12 col-sm-8 col-md-9 col-lg-10 right-content">
                                <p>Here we will host one final giveaway of 50 growlies and 3 Legendary Characters!
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4 trans">
                            <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2 left-content"><span style={{ fontSize: '30px' }}>100%</span></div>
                            <div className="col-xs-12 col-sm-8 col-md-9 col-lg-10 right-content">
                                <p>Animated short production begins!</p>
                            </div>
                        </div>
                    </div>


                </div>
                <div id="videos" className="row justify-content-center" style={{ margin: 0 }}>
                <video controls autoPlay  muted>
                        <source src="assets/template/img/fight.mp4" type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>

                </div>
                <div id="team" className="row pt-5" style={{ margin: 0 }} >
                    <div className="col-12 text-center pb-5">
                        <h2>The team</h2>
                    </div>
                    <div className="col text-center">
                        <div className="row mb-5 justify-content-center">
                            <div className="col-10 col-sm-10 col-md-3 col-lg-2">
                                <div className="card">
                                    <a href="https://twitter.com/artbyonem" target="_blank">
                                        <img className="card-img-top img-fluid rounded-circle" src="assets/template/img/team4.png" alt="Card cap" />
                                    </a>
                                    <a href="https://twitter.com/artbyonem" target="_blank">
                                    <div className="card-body" style={{position:'relative'}}>                                
                                        <p className="card-text">Onem</p>
                                        <div>Co-Founder</div>
                                        <div>Lead ArtistCreative</div>
                                        <div>Creative Strategist</div>
                                        <div>Creature Wizard</div>
                                        <div>Creator of Worlds</div>     
                                        <div style={{position:'relative'}}>&nbsp;</div>   
                                        <i className="fa-brands fa-twitter ico-twitter" ></i>                                                       
                                    </div>
                                    </a>     
                                </div>
                            </div>
                            <div className="col-10 col-sm-10 col-md-3 col-lg-2">
                                <div className="card  border-0">
                                    <a href="https://twitter.com/c_reu_d" target="_blank">
                                        <img className="card-img-top img-fluid rounded-circle" src="assets/template/img/team3.png" alt="Card cap" />
                                    </a>
                                    <a href="https://twitter.com/c_reu_d" target="_blank">
                                    <div className="card-body" style={{position:'relative'}}>
                                        <p className="card-text">CreuD</p>
                                        <div>Co-Founder</div>
                                        <div>Lead Artist</div>
                                        <div>Tailor Blacksmith and Weapon Master</div>
                                        <div style={{position:'relative'}}>&nbsp;</div>
                                        <i className="fa-brands fa-twitter ico-twitter" ></i>
                                    </div>
                                    </a>
                                </div>
                            </div>
                            <div className="col-10 col-sm-10 col-md-3 col-lg-2">
                                <div className="card  border-0">
                                    <a href="https://twitter.com/Daro_Bit" target="_blank">
                                        <img className="card-img-top img-fluid rounded-circle" src="assets/template/img/team2.png" alt="Card cap" />
                                    </a>
                                    <a href="https://twitter.com/Daro_Bit" target="_blank">
                                    <div className="card-body" style={{position:'relative'}}>
                                        <p className="card-text">Daro</p>
                                        <div>Co-Founder</div>
                                        <div>Lead Communications</div>
                                        <div>Lead Development</div>
                                        <div style={{position:'relative'}}>&nbsp;</div>
                                        <i className="fa-brands fa-twitter ico-twitter" ></i>
                                    </div>
                                    </a>
                                </div>
                            </div> 
                            <div className="col-10 col-sm-10 col-md-3 col-lg-2">
                                <div className="card  border-0">
                                    <a href="https://twitter.com/ImDittoNFT" target="_blank">
                                        <img className="card-img-top img-fluid rounded-circle" src="assets/template/img/team1.png" alt="Card cap" />
                                    </a>
                                    <a href="https://twitter.com/ImDittoNFT" target="_blank">
                                    <div className="card-body" style={{position:'relative'}}>
                                        <p className="card-text">Ditto</p>
                                        <div>Team Leader</div>
                                        <div>Discord Master</div>
                                        <div>Project Management</div>
                                        <div style={{position:'relative'}}>&nbsp;</div>
                                        <i className="fa-brands fa-twitter ico-twitter"></i>
                                    </div>
                                    </a>
                                </div>
                            </div>
                            <div className="col-10 col-sm-10 col-md-3 col-lg-2">
                                <div className="card  border-0">
                                    <a href="https://twitter.com/BriesMom22" target="_blank">
                                        <img className="card-img-top img-fluid rounded-circle" src="assets/template/img/team.png" alt="Card cap" />
                                    </a>
                                    <a href="https://twitter.com/BriesMom22" target="_blank">
                                    <div className="card-body" style={{position:'relative'}}>
                                        <p className="card-text">Jules</p>
                                        <div>Social Media Handler</div>
                                        <div>Mod Master</div>
                                        <div>Screenwriter</div>
                                        <div style={{position:'relative'}}>&nbsp;</div>
                                        <i className="fa-brands fa-twitter ico-twitter" ></i>
                                    </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>

        </React.Fragment>
    )

} 