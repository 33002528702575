import React, { useEffect, useState } from 'react'


const PreWhitelistPage = () => {

    return (
        <div className="row banner" style={{ margin: 0, zIndex: 100 }}>
            <img src="assets/template/img/registroWL.png" className="img-fluid m-auto" alt="banner" />

            <div className="waiting">
                <div className="preWhite">
                    <img className='img-whitelist' src="assets/template/img/tooth.png" alt="tooth-menu" />
                    <p className='text-preWhite' style={{ fontSize: '35px' }} >Congratulations!</p>
                    <p className='text-preWhite' style={{ fontSize: '18px', textTransform: 'none', fontFamily: 'Averta' }} >
                        You are one step closer to getting a chance to be on WL before the mint date!</p>
                    {/* <p className='text-preWhite mt-3' style={{ textTransform: 'none' }} >Pre-sale: March 12th @ 12PM CST</p> */}
                </div>

            </div>
        </div>
    )
}

export default PreWhitelistPage